.mix-three-gradient {
  background: linear-gradient(to right, #089dca, #0cc6ff, #94e6ff);
}

.bg-orange-gradient {
  background: linear-gradient(0deg, #F5AD7E 0%, #F48347 100%)
}

.bg-theme-gradient {
  background: linear-gradient(0deg, #38beaa 0%, #289383 100%)
}

.bg-pinkdark-gradient {
  background: linear-gradient(to right, #cf10bf, #FCD5E6);
}

.bg-pink-gradient {
  background: radial-gradient(circle, #FCD5E6 40%, #FFE7FD 100%);
}

.gradient-transparent {
  background: linear-gradient(to right, #E7BDF982, #FFFFFF1F)
}


.bg-gradient-pink-slow {
  background: linear-gradient(to right, #F1F1F180)
}


.gradient-text {
  background: linear-gradient(90deg, #FA939D, #8418F5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text sky {
  background: linear-gradient(90deg, #3AB7FD, #D98EFB, #F67894);
}

.gradient-text violet {
  background: linear-gradient(90deg, #8418F5, #FA939D);
}

.bg-gray {
  background: #F6F5F7
}

.bg-orange {
  background: #F48347
}

.bg-footer-color {
  background: #030F2E
}

.text-orange {
  color: #F48347
}

.text-dark-blue {
  color: #033D67
}

.shadow-sky {
  box-shadow: 0px 3px 20px 0px #ecf8ff;
}

.shadow-blue {
  box-shadow: 0px 5px 20px 0px #d5d6ff;
}

.shadow-pink {
  box-shadow: 0px 5px 20px 0px #f0cfff;
}


.shadow-white-up {
  object-fit: cover;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
}



.gradient-border {
  background: linear-gradient(white, white) padding-box,
      linear-gradient(to right, #3AB7FD, #D98EFB, #F67894) border-box;
  border: 1px solid transparent;
}


.text-theme-2 {
  color: #289383;
}

.text-gray2 {
  color: #464646;
}

.text-gray {
  color: #232323;
  transition: font-size 0.3s ease, color 0.3s ease;
}

.hover-to-change-color:hover + div,
  .hover-to-change-color:hover{
  color: #289383;
  font-size: 17px;
}

.animation-hover{
  transition: transform 0.3s ease,
}

.animation-hover:hover{
  transform: scale(1.1);
}

.border-bottom-theme {
  border-bottom: 1px solid #289383;
}

.border-bottom-orange {
  border-bottom: 1px solid #ee996b;
}


.image-contain{
  background-size: cover;
}


.w-86p{
  width: 86%;
}


.hover-know-more:hover{
  background: #033D67 !important;
  color: #fff !important;
  border: none;
  transition: background 0.6s ease, color 0.6s ease;
}

.hover-in-section .child {
  -webkit-text-fill-color: black; /* Default text color when not hovered */
  transition: background 0.6s ease, -webkit-text-fill-color 0.6s ease;
}


.hover-in-section:hover .child{
  background: linear-gradient(90deg, #3AB7FD, #D98EFB, #F67894);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* transition: background 1.2s ease, -webkit-text-fill-color 1s ease, -webkit-background-clip 0.8s ease; */
}