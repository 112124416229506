.logo {
    width: 45px;
    margin-right: 10px;
}

.logo_footer {
    width: 40px;
    margin-right: 10px;
}

.partner-logo {
    width: 100px !important;
}

.icon_20 {
    font-size: 28px;
    color: #089dca;
}

/* wrap line word */
.word-break-line {
    word-break: break-word;
    overflow-wrap: break-word;
}


/* Grid Sizes*/
.lg-1-5 {
    flex: 0 0 calc(100% / 12 * 1.5);
    max-width: calc(100% / 12 * 1.5);
}

.xl-1-5 {
    flex: 0 0 calc(100% / 12 * 1.5);
    max-width: calc(100% / 12 * 1.5);
}

/* For xxL screens (≥1400px) */
@media (min-width: 1400px) and (max-width: 3000px) {
    .custom-col {
        flex: 0 0 calc(100% / 12 * 8) !important;
        max-width: calc(100% / 12 * 8) !important;
    }
}

/* Only For Packages Box's */

/* For Extra Large screens (≥1200px) */
@media (min-width: 1200px) and (max-width: 1400px) {
    .xl-4 {
        flex: 0 0 calc(100% / 12 * 3) !important;
        max-width: calc(100% / 12 * 3) !important;
    }
}

/* For Large screens (≥992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .lg-3 {
        flex: 0 0 calc(100% / 12 * 4) !important;
        max-width: calc(100% / 12 * 4) !important;
    }
}

@media (min-width: 846px) and (max-width: 991.98px) {
    .md-3 {
        flex: 0 0 calc(100% / 12 * 4) !important;
        max-width: calc(100% / 12 * 4) !important;
    }
}

/* For Small screens (≥576px) */
@media (min-width: 576px) and (max-width: 845.98px) {
    .sm-2 {
        flex: 0 0 calc(100% / 12 * 6) !important;
        max-width: calc(100% / 12 * 6) !important;
    }
}

@media (max-width: 575.98px) {
    .xs-1 {
        flex: 0 0 calc(100% / 12 * 12) !important;
        max-width: calc(100% / 12 * 12) !important;
    }
}


/* xmd */
@media (max-width: 1290px) {
    .lg-1-2 {
        flex: 0 0 calc(100% / 12 * 1.5) !important;
        max-width: calc(100% / 12 * 1.5) !important;
    }
}

@media (max-width: 990px) {
    .md-1-5 {
        flex: 0 0 calc(100% / 12 * 2) !important;
        max-width: calc(100% / 12 * 2) !important;
    }

    .xmd-6 {
        flex: 0 0 calc(100% / 12 * 4) !important;
        max-width: calc(100% / 12 * 4) !important;
    }
}

@media (max-width: 800px) {
    .sm-1-5 {
        flex: 0 0 calc(100% / 12 * 3) !important;
        max-width: calc(100% / 12 * 3) !important;
    }

    .xmd-6 {
        flex: 0 0 calc(100% / 12 * 6) !important;
        max-width: calc(100% / 12 * 6) !important;
    }
}

@media (max-width: 555px) {
    .xs-1-5 {
        flex: 0 0 calc(100% / 12 *4) !important;
        max-width: calc(100% / 12 *4) !important;
    }
}

@media (max-width: 565px) {
    .xmd-6 {
        flex: 0 0 calc(100% / 12 * 12) !important;
        max-width: calc(100% / 12 * 12) !important;
    }
}

/* gradient colors */
.bg-gradient-success {
    background: linear-gradient(to right, #7cf798, #22d84a) !important;
}

.bg-gradient-white {
    background: linear-gradient(to top, #ffffff, #ffffff, #f9fafb) !important;
}

.bg-gradient-dark {
    background: linear-gradient(to right, #727272, #001705) !important;
}

.bg-gradient-danger {
    background: linear-gradient(to right, #ed9393, #f13e3e) !important;
}

.bg-gradient-blue {
    background: linear-gradient(to right, #89acff, #2667ff) !important;
}

.bg-gradient-lightblue {
    background: linear-gradient(to right, #f3f6ff, #d7e3ff) !important;
}

.bg-gradient-orangedark {
    background: linear-gradient(to right, #ffae82, #ff813c) !important;
}

.bg-gradient-thm {
    background: linear-gradient(to right, #33cfff, #089dca) !important;
}

.bg-gradient-box {
    background: linear-gradient(to top, #dfe9f3 0%, rgb(250, 250, 250) 100%) !important;
}

.bg-gradient-lightthm {
    background: linear-gradient(to right, #ffeae0, #ffcdb3) !important;
}

.bg-gradient-brown {
    background: linear-gradient(to right, #ffbab3, #e98175) !important;
}

.gradient-text {
    background: linear-gradient(to right, #33cfff, #ff813c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-text-theme {
    background: linear-gradient(to right, #33cfff, #94e6ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-text-voilet {
    background: linear-gradient(90deg, #FA939D, #8418F5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-sky {
    background: #dfe9f3 !important;
}


:root {
    --fs-xs1: 9px;
    --fs-xs2: 10px;
    --fs-xs: 11px;
    --fs-sm: 12px;
    --fs-md: 15px;
    --fs-lg: 16px;
    --fs-xl: 17px;
    --fs-xxl: 18px;
    --fs-3xl: 22px;
    --fs-4xl: 24px;
    --fs-5xl: 26px !important;
    --fs-6xl: 27px;
    --fs-7xl: 29px;
    --fs-8xl: 37px;
    --fs-9xl: 40px;
    --fs-10xl: 43px;
    --fs-11xl: 52px;
    --fs-12xl: 140px;

    /* --------------------------------- Color --------------------------------- */
    --text-gray: #444;
    --text-gray2: #666;
    --text-darkmedium: #2d3134;
    --text-thm: #089dca;
    --text-gold: #ff6334;
    --text-thmdark: #078db6;
    --text-light: #eaeaea;
    --text-violet: #7e22ce;
    /* ---------------------------------bg color--------------------------------- */
    --bg-gray: #333333;
    --bg-gray2: #aaa;
    --bg-lightgray: #f8f4f4;
    --bg-page-bg-thm: #ffe9cd;
    --bg-graylight: #eaeaea;
    --bg-gold: #ffb551;
    --bg-warn: #fff9f3;
    --bg-thm: #089dca;
    /* --bg-thm: #b3efe5; */

    --bg-violet: #7e22ce;
    --bg-violet-light: rgb(214, 228, 255);
    --bg-light: #f7f6fe;
    --bg-thmlight: #c1f0ff;
    --bg-bg-gray: #f9f9f9;
    --bg-thmlightdark: #e7f9ff;
    --bg-thmlight1: #e5fbff;
    --bg-thmdark: #089dca;
    --bg-yellow: #FFD482;
    --bg-yellow-light: #fff3ec;
    /* --bg-yellow-light: rgba(250, 132, 67, 0.2); */
}

.fs_9 {
    font-size: var(--fs-xs1) !important;
}

.fs_10 {
    font-size: var(--fs-xs2) !important;
}

.fs_11 {
    font-size: var(--fs-xs) !important;
}

.fs_12 {
    font-size: var(--fs-sm);
}

.fs_13 {
    font-size: 13px !important;
}

.fs_14 {
    font-size: 14px !important;
}

.fs_15 {
    font-size: var(--fs-md) !important;
}

.fs_16 {
    font-size: var(--fs-lg) !important;
}

.fs_17 {
    font-size: var(--fs-xl) !important;
}

.fs_18 {
    font-size: var(--fs-xxl) !important;
}

.fs_20 {
    font-size: 20px !important;
}

.fs_22 {
    font-size: var(--fs-3xl) !important;
}

.fs_24 {
    font-size: var(--fs-4xl) !important;
}

.fs_26 {
    font-size: var(--fs-5xl) !important;
}

.fs_29 {
    font-size: var(--fs-7xl) !important;
}

.fs_35 {
    font-size: 35px !important;
}

.fs_40 {
    font-size: 40px !important;
}


.TextMedium {
    font-size: 13px;
    color: #888;
    font-weight: 500;
}

.TextBold {
    color: #089dca;
    font-weight: 700;
}

.TxtCut {
    text-decoration: line-through;
}

.pointer {
    cursor: pointer;
}

.pointer-none {
    cursor: auto;
}

.pointer-notallowed {
    cursor: not-allowed;
}

.cursor-auto-1 {
    cursor: auto !important;
}


.text_warn {
    color: var(--text-warn) !important;
}

.text-brown {
    color: #b44f44 !important;
}

.text_gray {
    color: var(--text-gray) !important;
}

.text_gray2 {
    color: var(--text-gray2) !important;
}

.text_dark_medium {
    color: var(--text-darkmedium) !important;
}

.text_lightgray {
    color: var(--text-light) !important;
}

.text_gold {
    color: var(--text-gold) !important;
}

.text_thm {
    color: var(--text-thm) !important;
}

.text_blue {
    color: #1772eb !important;
}

.text_violet {
    color: var(--text-violet) !important;
}

.text_thmdark {
    color: var(--text-thmdark) !important;
}

.text_20 {
    color: #202020 !important;
}

.outline-none:focus {
    outline: none !important;
}

.no-outline textarea:focus {
    outline: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
}



/* bg */

.bg_warn {
    background-color: var(--bg-warn) !important;
}

.bg_gray {
    background-color: var(--bg-gray) !important;
}

.bg_gray2 {
    background-color: var(--bg-gray2) !important;
}

.bg_thmlight {
    background-color: var(--bg-thmlight) !important;
}

.bg_themelight {
    background-color: var(--bg-thmlight1) !important;
}

.bg_lightblue {
    background-color: #f5f8fa !important;
}

.bg_thmlightdark {
    background-color: var(--bg-thmlightdark) !important;
}

.bg-gray {
    background-color: var(--bg-bg-gray) !important;
}

.bg_lightgray {
    background-color: var(--bg-lightgray) !important;
}

.bg_graylg {
    background-color: var(--bg-graylight) !important;
}

.bg_thm {
    background-color: var(--bg-thm) !important;
}

.bg_thmdark {
    background-color: var(--bg-thmdark) !important;
}

.page-bg-thm-light {
    background-color: var(--bg-page-bg-thm) !important;
}

.bg-yellow {
    background-color: var(--bg-yellow) !important;
}

.bg-yellow-light {
    background-color: var(--bg-yellow-light) !important;
}

.bg_violet {
    background-color: var(--bg-violet) !important;
}

.bg_violet-light {
    background-color: var(--bg-violet-light) !important;
}

.bg_blue {
    background-color: #1772eb !important;
}

.bg-light-green {
    background: #e8fcfb !important;
}

.bg-thm-gradient {
    background: linear-gradient(40deg, #fdc2a1, #089dca);
}

/* .bg_thm1 {
    background-color: var(--bg-thm1) !important;
} */


/* left, right, top, bottom */
.rt-0 {
    right: 0 !important;
}

/* Margin */

.mt-30px {
    margin-top: -30px !important;
}

.ms-10px {
    margin-left: -10px !important;
}

.ms-0px {
    margin-left: 0px !important;
}

.mt-4-7 {
    margin-top: 40px !important;
}

.mt-4-5 {
    margin-top: 2rem !important;
}

.mt-2-5 {
    margin-top: 12px !important;
}

.mt-6 {
    margin-top: 58px !important;
}

.mt-7 {
    margin-top: 72px !important;
}

.mt-12 {
    margin-top: 110px !important;
}

.mt-13 {
    margin-top: 125px !important;
}

.mr_25 {
    margin-right: 25px !important;
}

.mr_16 {
    margin-right: 16px !important;
}

.mr_5 {
    margin-right: 5px !important;
}

.mr_10 {
    margin-right: 10px !important;
}

.mx-0-7 {
    margin-left: 7px !important;
    margin-right: 7px !important;
}

.mx-0-2 {
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.mx-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.mx-2-5 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}


/* Radius */
.rd_15 {
    border-radius: 15px !important;
}

.rd_10 {
    border-radius: 10px !important;
}

.rd_50 {
    border-radius: 50px;
}

.rd_47 {
    border-radius: 47px;
}

.rd_30 {
    border-radius: 30px;
}

.rd_6 {
    border-radius: 6px !important;
}

.rd_4 {
    border-radius: 4px !important;
}

.rd_tr_br_4 {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.rd_tr_br_6 {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.rd_tl_tr_6 {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}

.rd_bl_br_6 {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

/* border */

.border_warn {
    border: 1px solid #fc8c14 !important;
}

.border_white {
    border: 1px solid #fff !important;
}

.border_gray {
    border: 1px solid #d5d5d5 !important;
}

.border_dark {
    border: 1px solid #222 !important;
}

.border_thmlight {
    border: 1.5px solid #42cef8c5 !important;
}

.border_thm {
    border: 1px solid #089dca !important;
}

.border-bottom-thm {
    border-bottom: 1px solid #7de1ff !important;
}

.border_thmdark {
    border: 1px solid #089dca !important;
}

.border_bottom {
    border-bottom: 1px solid #d5d5d5 !important;
}

.border_thm-left {
    border-left: 1px solid #089dca !important;
}

.border_gray-top {
    border-top: 1px solid #d5d5d5 !important;
}


/* border width */

.br-3 {
    border-width: 3px !important;
}

.br-2 {
    border-width: 2px !important;
}

/* border colors */

.brc-secondary {
    border-color: #b5b5b5 !important;
}

.brc-dark {
    border-color: #666 !important;
}

.divider {
    height: 1px !important;
    background-color: #e5e5e5;
}

.divider-2 {
    height: 3px !important;
    background-color: #e5e5e5;
}

/* Padding */
.pr-5 {
    padding-right: 5px !important;
}

.p-0-5 {
    padding: 2px;
}

.p-1-5 {
    padding: 6px !important;
}

.p-2-5 {
    padding: 12px !important;
}

.pt-0-5 {
    padding-top: 2px !important;
}

.pt-4px {
    padding-top: 3px !important;
}

.pt-1-5 {
    padding-top: 6px !important;
}

.pt-4-5 {
    padding-top: 32px !important;
}

.pb-4-5 {
    padding-bottom: 32px !important;
}

.pb-2-5 {
    padding-bottom: 12px !important;
}

.px-1-5 {
    padding-left: 9px !important;
    padding-right: 9px !important;
}

.px-2-5 {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.ps-2-5 {
    padding-left: 12px !important;
}


/* Height & Width */

.h_auto{
    height: auto !important;
}

.hw_80-85px {
    height: 80px !important;
    width: 85px !important;
}

.hw_80px {
    height: 80px !important;
    width: 80px !important;
}

.hw_70px {
    height: 70px !important;
    width: 70px !important;
}

.hw_55px {
    height: 55px !important;
    width: 55px !important;
}

.hw_50px {
    height: 50px !important;
    width: 50px !important;
}

.hw_45px {
    height: 45px !important;
    width: 45px !important;
}

.hw_35px {
    height: 35px !important;
    width: 35px !important;
}

.hw_30px {
    height: 30px !important;
    width: 30px !important;
}

.hw_28px {
    height: 28px !important;
    width: 28px !important;
}

.hw_25px {
    height: 25px !important;
    width: 25px !important;
}

.hw_22px {
    height: 22px !important;
    width: 22px !important;
}

.hw_15px {
    height: 15px !important;
    width: 15px !important;
}

.hw_20px {
    height: 20px !important;
    width: 20px !important;
}

.h-600px {
    height: 530px !important;
}

.h_520px {
    height: 520px !important;
}

.h-87p {
    height: 88%;
}

.h_470px {
    height: 530px;
}

.h-470px {
    height: 470px;
}

.h-460px {
    height: 460px;
}

.h_400px {
    height: 450px !important;
}

.h_380px {
    height: 380px !important;
}

.h_350px {
    height: 350px !important;
}

.h_300px {
    height: 300px !important;
}

.h_270px {
    height: 270px !important;
}

.h_280px {
    height: 280px !important;
}

.h_250px {
    height: 250px !important;
}

.h_230px {
    height: 230px !important;
}

.h_200px {
    height: 200px !important;
}

.h_180px {
    height: 180px !important;
}

.h_165px {
    height: 165px !important;
}

.h_150px {
    height: 150px !important;
}

.h_130px {
    height: 130px !important;
}

.h_120px {
    height: 120px !important;
}

.h_100px {
    height: 100px !important;
}

.h_90px {
    height: 90px !important;
}

.h_80px {
    height: 80px !important;
}

.h_75px {
    height: 75px !important;
}

.h_65px {
    height: 65px !important;
}

.h_50px {
    height: 50px !important;
}

.h_45px {
    height: 45px !important;
}

.h_40px {
    height: 40px !important;
}

.h_38px {
    height: 38px !important;
}

.h_35px {
    height: 35px !important;
}

.h_30px {
    height: 30px !important;
}

.h_22px {
    height: 22px !important;
}

.h_18px {
    height: 18px !important;
}

.h_4px {
    height: 4px !important;
}

.h_05px {
    height: 0.5px !important;
}

.h_90 {
    height: 90% !important;
}

.h_70 {
    height: 70% !important;
}

.h_60 {
    height: 60% !important;
}

.h_0-5px {
    height: 0.9px !important;
}

.w_600px {
    width: 600px !important;
}

.w_400px {
    width: 400px !important;
}

.w_340px {
    width: 340px !important;
}

.w_300px {
    width: 300px !important;
}

.w_280px {
    width: 280px !important;
}

.w_250px {

    width: 260px !important;
}

.w_200px {
    width: 200px !important;
}

.w_150px {
    width: 150px !important;
}

.w_120px {
    width: 120px !important;
}

.w_100px {
    width: 100px !important;
}

.w_95px {
    width: 95px !important;
}

.w_80px {
    width: 80px !important;
}

.w_60px {
    width: 60px !important;
}

.w_40px {
    width: 40px !important;
}

.w_35px {
    width: 35px !important;
}

.w_25px {
    width: 25px !important;
}

.w_20 {
    width: 20% !important;
}

.w_30 {
    width: 30% !important;
}

.w_35 {
    width: 35% !important;
}

.w_40 {
    width: 40% !important;
}

.w_49 {
    width: 49% !important;
}

.w_60 {
    width: 60% !important;
}

.w_65 {
    width: 65% !important;
}

.w_70 {
    width: 70% !important;
}

.w_86 {
    width: 86% !important;
}

.w_90 {
    width: 90% !important;
}

.w_92 {
    width: 93.5% !important;
}

.w_95 {
    width: 95% !important;
}

.w_97s {
    width: 97%;
}

.w_97 {
    width: 99%;
}

.min-w-200px {
    width: 500px !important;
}



.subheading {
    color: #089dca;
    display: block !important;
    font-family: "Arizonia", cursive;
}

.lato {
    font-family: 'Lato', sans-serif;
}

.Sansation_Bold {
    font-family: 'Sansation_Bold';
}

.Sansation_Regular {
    font-family: 'Sansation_Regular';
}


/* Gap */

.rg_30px {
    row-gap: 30px !important;
}

/* Animation & Keyframes & shadow & hover or positions & Align*/

.d_flex_align_justify_center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.justify-between {
    justify-content: space-between !important;
}

.shadow-n1 {
    box-shadow: 0px 1px 2px #c5c5c5;
}

.shadow-n {
    box-shadow: 0px -0.2px 4px #c5c5c5;
}

.shadow-clean {
    box-shadow: 0px 5px 10px #e3e3e3;
}

.shadow-slowclean-blue {
    box-shadow: 0px 2px 5px #a5c0ff;
}

.shadow-slowclean {
    box-shadow: 0px 2px 5px #eeeeee;
}

.shadow-slowclean-dark {
    box-shadow: 0px 4px 10px #7c7c7c;
}

.shadow-gray {
    box-shadow: 0px 2px 4px #fcb58f;
}

.text-shadow {
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.6) !important;
}


/* hover trans animation */

.shadow-clean-hover:hover {
    background: #fff;
    box-shadow: 0px 5px 10px #e3e3e3;
}

.move_ltr {
    position: relative;
    transition: all 0.3s ease;
}

.move_ltr:hover {
    transform: translateX(10px);
}

.move_btt {
    position: relative;
    transition: all 0.3s ease;
}

.move_btt:hover {
    box-shadow: 0px 2px 5px #eeeeee;
    transform: translateY(-10px);
}

.hover_btt {
    position: relative;
    transition: all 0.3s ease;
}

.hover_btt:hover {
    background-color: #fff;
    transform: translateY(-10px);
}

.hover-bg-lightblue {
    position: relative;
    transition: background 0.3s ease-in, color 0.3s ease-in;
}

.hover-bg-lightblue:hover {
    color: #fff !important;
    background: #64dbff !important;
}

.pot-0 {
    top: 0 !important;
}

.pl-0 {
    left: 0 !important;
}

.pr-0 {
    right: 0 !important;
}

.pr-5 {
    right: 5 !important;
}

@keyframes moveUp {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-10px);
    }
}

.hover-bold-text:hover {
    /* font-weight: 600 !important; */
    font-family: 'Source_Medium' !important;
}

.fade-custom {
    animation: all 1s ease;
}


/* Shape */

.shape-top {
    position: relative;
    top: -5.5px;
    left: 55px;
    width: 49px;
    margin: auto;
}

.shape-top img {
    width: 100%;
    height: 100%;
}

.shape-bottom {
    position: relative;
    width: 30px;
    left: -50px;
    margin: auto;
}



/* Heading UnderLine */

.heading-underline {
    position: relative;
    display: inline-block;
}

.heading-underline::after {
    content: '';
    display: block;
    width: 100%;
    height: 1.9px;
    background-color: #555;
    margin: 0px auto;
    position: relative;
}

.underline_thmdark::after {
    background-color: #089dca !important;
}

.active-underline {
    position: relative;
    display: inline-block;

}

.active-underline::after {
    content: '';
    position: relative;
    display: block;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 3px;
    width: 100%;
    top: 4px;
    background-color: #089dca;
}


.hover-underline {
    position: relative;
    display: inline-block;
}

.hover-underline::after {
    content: '';
    position: absolute;
    left: 5%;
    bottom: -4px;
    width: 50%;
    height: 1.4px;
    background-color: #089dca;
    opacity: 0;
    transition: opacity 0.3s, width 0.3s, left 0.3s;
}

.link-hover {
    text-decoration: none;
    transition: text-decoration 0.3s ease;
}

.link-hover:hover {
    text-decoration: underline;
}
  


.hover-bold-text:hover::after {
    opacity: 1;

}

.scrollable-1 {
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
}

.scrollable {
    overflow: auto;
    scrollbar-width: none;
}

.scrollable::-webkit-scrollbar {
    display: none;
}

.scrollable-h {
    overflow-x: auto;
    white-space: nowrap
}


.overflow-x-auto {
    overflow-x: auto !important;
}


/* Custom Arrow Left */
.custom_arrow {
    z-index: 1 !important;
    margin-left: 0px !important;
    min-height: 32px !important;
    min-width: 28px !important;
    background-color: #e5f8ff !important;
}

.custom_arrow_slick {
    z-index: 1 !important;
    position: absolute;
    background-color: #e5f8ff !important;
}

/* Ensure the carousel container takes full width and has no margin */
.slick-slider {
    width: 100%;
    margin: 0 auto;
}

.slick-track {
    display: flex;
    align-items: flex-start;
    width: auto;
    margin: 0;
}

.slick-slide {
    display: inline-block;
    width: auto;
    margin: 0;
}

.slick-list {
    overflow: hidden;
}


.slick-next:before {
    display: none !important;
}

.slick-prev:before {
    display: none !important;
}





/* My Button */
.button {
    padding: 8px 20px 10px 20px;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    width: 120px;
    cursor: pointer;
    text-align: center;
}

/* Collapsed */

.collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.expanded {
    display: block;
}

.mini-line-1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mini-line-1.two {
    -webkit-line-clamp: 2;
}

.mini-line-1.ten {
    -webkit-line-clamp: 10;
}

.mini-line-1.twentyfive {
    -webkit-line-clamp: 25;
}



/* Common Media */

@media (max-width:991.5px) {
    .media-mt-2 {
        margin-top: 8px !important;
    }
}

@media (max-width:519px) {
    .d-none-519 {
        display: none !important;
    }

    .px-xs-4 {
        padding-right: 24px !important;
        padding-left: 24px !important;

    }
}

@media (min-width:620px) {
    .w-lg-350px {
        width: 500px !important;
    }
}


/* Custom Field */
.custom_field {
    height: 40px;
    border-radius: 4px;
    color: #444;
}

/* Default radio button styling */
.custom-radio {
    accent-color: #07b907 !important;
    /* Default color */
}

.custom-radio:checked {
    accent-color: #400606 !important;
    /* Color when checked */
}

/* image color */
.img-white {
    filter: brightness(0) invert(1) sepia(1) saturate(5) hue-rotate(180deg);
}

.img-gray {
    filter: grayscale(100%),
}

.img-blue {
    filter: brightness(0) saturate(100%) invert(39%) sepia(40%) saturate(1838%) hue-rotate(205deg) brightness(97%) contrast(99%);
}

.img_thm {
    filter: invert(52%) sepia(85%) saturate(1000%) hue-rotate(-23deg) brightness(102%) contrast(102%);
}

/* Add this CSS to your stylesheet or style block */
.scroll-lock {
    overflow: hidden !important;
}


.rotate-60 {
    transform: rotate(-10deg);
}

.z-index-top {
    z-index: 99999999 !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

/* Example: Targeting the text directly within the Checkbox label */
.ant-checkbox-wrapper span {
    text-transform: capitalize !important;
}

.text-capitalize-fix {
    text-transform: lowercase !important;
    /* Remove any previous transformations */
}


/* image */
.set-original-size {
    background-size: contain;
}

.vh-50 {
    height: 90vh !important;
}


.remove-image-bg-white {
    mix-blend-mode: darken;
}